import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import UtilsPage from './Utils/UtilsPage';
import MainPage from './Main/MainPage';
import UserBoardPage from './UserBoard/UserBoardPage';
import EditorTestPage from './Editor/EditorTestPage';
import ViewerTestPage from './Editor/ViewerTestPage';
import IntroductionPage from './Introduction/IntroductionPage';
import AboutPage from './About/AboutPage';

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/utils" component={UtilsPage} />
                <Route path="/userboard/board1/:postId" component={UserBoardPage} />
                <Route path="/userboard/board2/:postId" component={UserBoardPage} />
                <Route path="/userboard" component={UserBoardPage} />
                <Route path="/editor" component={EditorTestPage} />
                <Route path="/viewer" component={ViewerTestPage} />
                <Route path="/about" component={AboutPage} />
                <Route path="/" component={IntroductionPage} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;