import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { TextField, Button, Typography, Container, Grid, Box } from '@mui/material';
import QrCodeDefaultImage from '../../../img/qrcode.png';

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

// 스타일링된 div 컴포넌트를 생성합니다.
const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0.8); /* 반투명한 흰색 배경을 설정합니다. */
    borderRadius: '10px', // 둥근 사각형을 위해 테두리를 둥글게 만듭니다.
    padding: '20px', // 내용물과의 간격을 조정합니다.
`;

// 스타일링된 컨테이너 컴포넌트를 생성합니다.
const StyledQRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// 스타일링된 QRCode 컴포넌트를 생성합니다.
const StyledQRCode = styled(QRCode)`
  width: 300px; // 원하는 크기로 조정합니다.
  height: 300px; // 원하는 크기로 조정합니다.
`;

function QRCodeGenerator() {
  const [text, setText] = useState('');

  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setText(e.target.value);
  };

  return (
    <CenteredContainer style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'relative',
        // backgroundColor: 'rgba(200, 200, 200, 0.8)', // 반투명한 배경색을 설정합니다.
        // borderRadius: '10px', // 둥근 사각형을 위해 테두리를 둥글게 만듭니다.
        padding: '20px', // 내용물과의 간격을 조정합니다.
      }}>
      <StyledQRCodeContainer>
        {
            text ?
                (<StyledQRCode value={text} />)
            :
                (<img src={QrCodeDefaultImage} width={128} height={128}/>)
        }
        <br />
        <input type="text" value={text} onChange={handleChange} placeholder='입력하세요' />
      </StyledQRCodeContainer>
    </CenteredContainer>
  );
}

export default QRCodeGenerator;