import React, { useEffect, useRef } from 'react';
import ToastEditor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

const Editor = () => {
    const toastEditorRef = useRef<ToastEditor | null>(null);

    useEffect(() => {
        toastEditorRef.current = new ToastEditor({
            el: document.querySelector('#editor')!,
            height: '500px',
            initialEditType: 'markdown',
            previewStyle: 'vertical'
        });

        toastEditorRef.current.getMarkdown();
    }, []);

    return (
        <>
            <div id="editor"></div>
            <button onClick={() => console.log(toastEditorRef.current?.getMarkdown())}>Get Markdown</button>
        </>
    );
};

export default Editor;