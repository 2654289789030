import React, { useState, useRef, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Container, Button, Snackbar } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative',
  // backgroundColor: 'rgba(200, 200, 200, 0.8)',
  // borderRadius: '10px',
  padding: '20px',
});

const GuidItem = styled.div`
  cursor: pointer;
  padding: 10px;
  margin: 10px 0;
  animation: ${fadeIn} 1s ease;
  border: 1px solid #ccc;
  background-color: #f0b429;
  transition: background-color 0.2s ease, transform 0.2s ease; /* 변화하는 속성에 대한 전환 효과 추가 */
  border-radius: 6px;

  &:hover {
    background-color: #dca127; /* 마우스 오버 시 배경색 변경 */
  }

  &:active {
    background-color: #c88f25; /* 클릭 시 배경색 변경 */
    transform: scale(0.98); /* 클릭 시 요소를 약간 축소 */
  }
`;

const GuidContainer = styled.div`
  text-align: center;
  overflow: auto;
  margin-top: 70px;
  max-height: calc(100vh - 200px);
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 50px;
  margin: 20px
`;

function GuidGenerator() {
  const [guids, setGuids] = useState<string[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const guidContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guidContainerRef.current) {
      guidContainerRef.current.scrollTop = guidContainerRef.current.scrollHeight; // 새 항목이 추가될 때마다 스크롤을 맨 위로 이동합니다.
    }
  }, [guids]); // guids 배열이 변경될 때마다 실행됩니다.

  const generateGuid = () => {
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    return guid;
  };

  
  const handleGenerate = () => {
    const guid = generateGuid();
    setGuids([...guids, guid]);
  };

  const handleCopy = async (guid: string) => {
    try {
      await navigator.clipboard.writeText(guid);
      setOpenSnackbar(true);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000); // 3초 후 사라짐
    } catch (error) {
      console.error('Copy failed', error);
    }
  };

  return (
    <CenteredContainer>
      <ButtonContainer>
        <Button variant="contained" onClick={handleGenerate}>
          GUID 생성
        </Button>
      </ButtonContainer>
      <GuidContainer ref={guidContainerRef}>
        {guids.map((guid, index) => (
          <GuidItem  key={index} onClick={() => handleCopy(guid)}>
            {guid}
          </GuidItem >
        ))}
      </GuidContainer>
      <Snackbar
        open={openSnackbar}
        message="복사되었습니다"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </CenteredContainer>
  );
}

export default GuidGenerator;