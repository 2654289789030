import React from 'react';
import { useParams } from 'react-router-dom';
import Viewer from '../Editor/Viewer';
import postsJson from '../../Data/Posts.json'

interface Params {
    postId: string;
}

const PostDetail = () => {
    const { postId } = useParams<Params>();
    const post = postsJson.posts.find((post: { id: string; }) => post.id === postId);

    return (
        <div>
            {post && (
                <>
                    <h1>{post.title}</h1>
                    <Viewer contents={post.content} />
                </>
            )}
        </div>
    );
};

export default PostDetail;