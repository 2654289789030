import React, { useEffect, useRef } from 'react';
import ToastViewer from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

function Viewer({ contents }: { contents: string }) {
    
    const toastEditorRef = useRef<ToastViewer | null>(null);

    useEffect(() => {
        toastEditorRef.current = ToastViewer.factory({
            el: document.querySelector('#viewer')!,
            viewer: true,
            initialValue: contents || ''
        }) as ToastViewer;
    }, []);

    return(
        <div id="viewer">
            <strong>The example code can be slower than your environment because the code is transpiled by
            babel-standalone in runtime.</strong>
        </div>
    );
}

export default Viewer;