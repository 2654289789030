import React, { useState } from 'react';
import { BrowserRouter, Link, Route, Switch, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../Sidebar';
import ReactGuidePage from './ReactGuidePage';
import Board2 from './Board2';
import PostDetail from './PostDetail';
import MainLayout from '../MainLayout';

const UserBoardContainer = styled.div`
    display: flex;
    height: auto;
    margin-top: 100px;
`;

const ContentsContainer = styled.main`
    flex-grow: 1;
    overflow-y: hidden; /* 내용이 넘치면 스크롤바 생성 */
    height: auto;
    width: 100%;
    padding: 15px;
    background-color: #f0f0f0;
`;

interface Params {
    postId: string;
}

const UserBoardPage = () => {
    const { postId } = useParams<Params>();
    const [selectedMenu, setSelectedMenu] = useState('ReactGuidePage');
    const handleMenuSelect = (menu: string) => {
      setSelectedMenu(menu);
    };

console.log(postId);

    return (
        <MainLayout handleMenuSelect={handleMenuSelect}>
            <UserBoardContainer>
                <ContentsContainer>
                    {selectedMenu === 'ReactGuidePage' && (postId ? <PostDetail/> : <ReactGuidePage />)}
                    {selectedMenu === 'board2' && (postId ? <PostDetail/> : <Board2 />)}
                </ContentsContainer>
            </UserBoardContainer>
        </MainLayout>
    );
};

export default UserBoardPage;