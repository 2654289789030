import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoImg from '../../img/theguys-logo.png';
import styled from 'styled-components';
import night_tree_river from '../../img/night_tree_river.jpg';
import cherry_blossoms from '../../img/cherry-blossoms-7136163_1920.jpg';
import aurora from '../../img/aurora-1197753_1920.jpg';
import autumn_leaves from '../../img/autumn-leaves-1309190_1920.jpg';
import view_1 from '../../img/view_1.jpg';
import view_2 from '../../img/view_2.jpg';
import view_3 from '../../img/view_3.jpg';
import view_4 from '../../img/view_4.jpg';
import MainLayout from '../MainLayout';
import Footer from '../Footer';



const ParentSticky = styled.section`
    position: relative;
    height: 1100px;
    // padding: 30px 0px;
    // scroll-snap-align: start;
    overflow: hidden;
`;

const ImgSticky = styled.img`
    position: sticky;
    top: 4px;
    width: 100%;
    opacity: 0;
    transform: scale(1) translateY(100%);
    transform-origin: center;

    ${ParentSticky}.on & {
        animation: combinedEffect 10s ease-in-out forwards;
    }

    @media (max-width: 768px) {
        height: calc(50%);
    }

    @keyframes combinedEffect {
        0% {
          transform: scale(1.1) translateY(100%); /* 초기 scale 및 translateY 값 */
          opacity: 0;
        }
        10% {
          transform: scale(1.1) translateY(0%); /* scale은 유지, translateY 시작 */
          opacity: 0.5;
        }
        20% {
          transform: scale(1.1) translateY(0%); /* scale은 유지, translateY 시작 */
          opacity: 1;
        }
        100% {
          transform: scale(1) translateY(0%); /* 최종 scale 및 translateY 값 */
          opacity: 1;
        }
      }
`;

const AbsoluteText = styled.div`
    position: absolute;
    // width: calc(100% - 100px);
    top: 0px;   
    left: 0px;
    color: white;
    font-size: 45px;
    font-weight: bold;
    padding: calc(25%) 100px;
    z-index: 15;

    @media (max-width: 768px) {
        font-size: 30px;
        padding: calc(60%) 40px;
    }

    transform: translateY(100%); /* 초기 위치를 아래로 설정하여 이미지 숨김 */
    transition: opacity 1.5s ease-out, transform 1.5s ease-out; /* 부드러운 애니메이션을 위한 설정 */
    opacity: 0;

    ${ParentSticky}.on & {
        opacity: 1;
        transform: translateY(0); /* 'on' 클래스가 추가되면 이미지가 원래 위치로 */
    }
`;

const MoreButton = styled.a`
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-size: 20px;
    transition: background 0.3s, color 0.3s;
    text-decoration: none;

    &:hover {
        background: #FFF3;
        color: black;
    }
`;

const Blind = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #0008;
    mix-blend-mode: hue;
    height: 100%;
    width: 100%;
    z-index: 15;
`;

const IntroductionPage = () => {
    // 각 섹션에 대한 참조를 저장할 배열
    const sectionRefs = useRef<HTMLElement[]>([]);
  
    useEffect(() => {
        const handleScroll = () => {
            console.log('scroll');
            sectionRefs.current.forEach((section, index) => {
                const rect = section?.getBoundingClientRect();
                
                if (rect?.top < window.innerHeight / 2) {
                    section?.classList.add('on');
                } else if (rect?.top > window.innerHeight / 2) {
                    if (index > 0) section?.classList.remove('on'); // 이전 섹션은 on을 유지
                }
          });
        };
    
        document.querySelector(".main-content")?.addEventListener('scroll', handleScroll);
        handleScroll(); // 컴포넌트 마운트 시 초기 상태도 체크
    
        return () => {
            document.querySelector(".main-content")?.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <MainLayout isFixed={true}>
            <ParentSticky ref={el => sectionRefs.current.push(el!)}>
                <ImgSticky src={view_1} alt="view_1" />
                <Blind />
                <AbsoluteText>
                    <div style={{color: 'green', fontSize: "16px"}}>
                        The Guys
                    </div>
                    QR Code 생성기<br />
                    <p style={{fontSize: "16px"}}>
                        택스트 정보를 QR 코드로 변환합니다.
                    </p>
                    <div>
                        <MoreButton href="/utils?selectedmenu=qrcode">
                            <div>
                                <span>
                                    <span>P</span>
                                    <span>L</span>
                                    <span>A</span>
                                    <span>Y</span>
                                </span>
                            </div>
                        </MoreButton>
                    </div></AbsoluteText>
            </ParentSticky>
            <ParentSticky ref={el => sectionRefs.current.push(el!)}>
                <ImgSticky src={view_2} alt="view_2" />
                <Blind />
                <AbsoluteText>
                    <div style={{color: 'green', fontSize: "16px"}}>
                        The Guys
                    </div>
                    Base64 변환기<br />
                    <p style={{fontSize: "16px"}}>
                        텍스트나 파일을 Base64 형식으로 인코딩합니다.
                    </p>
                    <div>
                        <MoreButton href="/utils?selectedmenu=base64">
                            <div>
                                <span>
                                    <span>P</span>
                                    <span>L</span>
                                    <span>A</span>
                                    <span>Y</span>
                                </span>
                            </div>
                        </MoreButton>
                    </div>
                </AbsoluteText>
            </ParentSticky>
            <ParentSticky ref={el => sectionRefs.current.push(el!)}>
                <ImgSticky style={{ margin: "90px 0px" }} src={view_3} alt="view_3" />
                <Blind />
                <AbsoluteText>
                    <div style={{color: 'green', fontSize: "16px"}}>
                        The Guys
                    </div>
                    GUID 생성기<br />
                    <p style={{fontSize: "16px"}}>
                        고유 식별자(GUID)를 생성합니다.
                    </p>
                    <div>
                        <MoreButton href="/utils?selectedmenu=guid">
                            <div>
                                <span>
                                    <span>P</span>
                                    <span>L</span>
                                    <span>A</span>
                                    <span>Y</span>
                                </span>
                            </div>
                        </MoreButton>
                    </div>
                </AbsoluteText>
            </ParentSticky>
            <Footer hasAds={true}/>
        </MainLayout>
    );
};

export default IntroductionPage;