import React from 'react';
import styled from 'styled-components';

interface FooterProps {
  hasAds?: boolean;
}

const FooterContainer = styled.footer`
  position: fixed;
  background-color: lightgray;
  padding: 0px;
  text-align: center;
  bottom: 0;
  left: 0;
  height: 50px important!;
  width: 100%;
  color: black;
  z-index: 1000;
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const NoAdsText = styled.p`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CoupangAd = styled.iframe`
  width: calc(100% - 15px);
  height: 70px;
`;

const Footer: React.FC<FooterProps> = ({ hasAds = false }) => {
  return (
    <FooterContainer>
      <ContentWrapper>
        {hasAds ? (
          <>
            <CoupangAd
              src="https://ads-partners.coupang.com/widgets.html?id=781934&template=carousel&trackingCode=AF9915925&subId=&width=400&height=70&tsource="
              referrerPolicy="unsafe-url"
              title="Advertisement"/>
          </>
        ) : (
          <NoAdsText>No ads</NoAdsText>
        )}
      </ContentWrapper>
    </FooterContainer>
  );
}

export default Footer;
