import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MainLayout from '../MainLayout';



const AboutContainer = styled.div`
  padding: 20px;
  padding-top: 100px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const AboutPage = () => {
    return (
        <MainLayout isFixed={true}>
            <AboutContainer>
                <Title>About Us</Title>
                <Description>
                    Welcome to our website. We are a team of passionate developers and designers committed to creating the best user experience. Our mission is to deliver high-quality software solutions that meet the needs of our clients.
                </Description>
                <Description>
                    Our team has a diverse background in web development, mobile applications, and UX/UI design. We believe in continuous learning and always strive to stay up-to-date with the latest technologies and trends.
                </Description>
                <Description>
                    Thank you for visiting our website. If you have any questions or would like to learn more about our services, please feel free to contact us.
                </Description>
            </AboutContainer>
        </MainLayout>
    );
};

export default AboutPage;