// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import night_tree_river from '../img/night_tree_river.jpg';
import cherry_blossoms from '../img/cherry-blossoms-7136163_1920.jpg';
import aurora from '../img/aurora-1197753_1920.jpg';
import autumn_leaves from '../img/autumn-leaves-1309190_1920.jpg';


const BannerSliderContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledSwiper = styled(Swiper)`
  height: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
`;

function BannerSlider() {
  const [swiper, setSwiper] = useState(null);
  
  SwiperCore.use([Navigation, Pagination, Autoplay]);

  function onSlideChange(swiper: SwiperCore) {
  }

  return (
    <BannerSliderContainer>
      <StyledSwiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        loop={true}
        autoplay={{ delay: 3000 }}
        onSwiper={(swiper) => onSlideChange(swiper)}
        >
        <SwiperSlide>
          <BannerImg src={night_tree_river} />
        </SwiperSlide>
        <SwiperSlide>
          <BannerImg src={cherry_blossoms} />
        </SwiperSlide>
        <SwiperSlide>
          <BannerImg src={aurora} />
        </SwiperSlide>
        <SwiperSlide>
          <BannerImg src={autumn_leaves} />
        </SwiperSlide>
        <SwiperSlide>
        <ins className="adsbygoogle"
             style={{display:"block"}}
             data-ad-client="ca-pub-7468630488639931"
             data-ad-slot="5029216737"
             data-ad-format="auto"
             data-ad-test="on"
             data-full-width-responsive="true"
        />
        </SwiperSlide>
      </StyledSwiper>
    </BannerSliderContainer>
  );
}

export default BannerSlider;