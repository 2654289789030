import React, { useState } from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../Sidebar';
import postsJson from '../../Data/Posts.json'

const Container = styled.div`
    margin: auto;
`;

const StyledLink = styled(Link)`
    color: #333;
    text-decoration: none;
    font-weight: bold;
    flex-grow: 1; /* Allows the title to expand and fill available space */

    &:hover {
    background-color: #f8f9fa;
    }
`;

const PostItem = styled.li`
    display: flex; /* Sets flexbox layout */
    align-items: center; /* Vertically center align items */
    border-bottom: 1px solid #ccc;
    padding: 15px;
    list-style-type: none;

    &:last-child {
    border-bottom: none;
    }
`;

const UserName = styled.div`
  font-size: 14px;
  color: #666;
  margin-left: 20px; /* Space between title and username */
`;

const ReactGuidePage = () => {
    return (
        <Container>
            <h1>React Guide Page</h1>
            <ul>
                {postsJson.posts.map((post : any) => (
                    <PostItem key={post.id}>
                        <StyledLink to={`/userboard/board1/${post.id}`}>{post.title}</StyledLink>
                        <UserName>{post.userName}</UserName>
                    </PostItem>
                ))}
            </ul>
        </Container>
    );
};

export default ReactGuidePage;