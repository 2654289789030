import React, { useState } from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../Sidebar';

const Container = styled.div`
    margin: auto;
`;

const StyledLink = styled(Link)`
    color: #333;
    text-decoration: none;
    font-weight: bold;
    flex-grow: 1; /* Allows the title to expand and fill available space */

    &:hover {
    background-color: #f8f9fa;
    }
`;

const PostItem = styled.li`
    display: flex; /* Sets flexbox layout */
    align-items: center; /* Vertically center align items */
    border-bottom: 1px solid #ccc;
    padding: 15px;
    list-style-type: none;

    &:last-child {
    border-bottom: none;
    }
`;

const UserName = styled.div`
  font-size: 14px;
  color: #666;
  margin-left: 20px; /* Space between title and username */
`;

const Board2 = () => {
    const [posts, setPosts] = useState([
        { id: 1, userName: "user1", title: "First post", content: "This is the first post" },
        { id: 2, userName: "user2", title: "Second post", content: "This is the second post" },
        { id: 3, userName: "user3", title: "Third post", content: "This is the third post" }
    ]);

    return (
        <Container>
            <h1>User Board Page</h1>
            <ul>
                {posts.map((post : any) => (
                    <PostItem key={post.id}>
                        <StyledLink to={`/userboard/board2/${post.id}`}>{post.title}</StyledLink>
                        <UserName>{post.userName}</UserName>
                    </PostItem>
                ))}
            </ul>
        </Container>
    );
};

export default Board2;