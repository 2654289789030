import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';

const InputTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    minHeight: '400px',
    textAlign: 'left',
  },
});

const ResultTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    minHeight: '400px',
    textAlign: 'left',
  },
});

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const SwapButton = styled(Button)({
  margin: '10px',
});

const ConvertButton = styled(Button)({
  margin: '10px',
});

const Base64Converter = () => {
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [isEncoded, setIsEncoded] = useState(true);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleToggleEncoding = () => {
    setIsEncoded((prev) => !prev);
    setInputText(outputText);
    setOutputText(inputText);
  };

  const handleConvert = () => {
    if (isEncoded) {
        const encoder = new TextEncoder();
        const decoder = new TextDecoder();

        const inputBytes = encoder.encode(inputText);
        const inputArray = Array.from(inputBytes);
        const base64 = btoa(String.fromCharCode.apply(null, inputArray));
        setOutputText(base64);
    } else {
        try {
          const text = Buffer.from(inputText, 'base64').toString('utf-8');
          setOutputText(text);
        } catch (error) {
          setOutputText('Invalid Base64 string');
        }
    }
  };
  
  return (
    <CenteredContainer style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      position: 'relative',
      // backgroundColor: 'rgba(200, 200, 200, 0.8)', // 반투명한 배경색을 설정합니다.
      // borderRadius: '10px', // 둥근 사각형을 위해 테두리를 둥글게 만듭니다.
      padding: '20px', // 내용물과의 간격을 조정합니다.
    }}>
      <Typography variant="h4" gutterBottom>
        Base64 Converter
      </Typography>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={5}>
          <TextField
            label="Input Text"
            multiline
            rows={10}
            fullWidth
            value={inputText}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleToggleEncoding}>
            {'<->'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Output Text"
            multiline
            rows={10}
            fullWidth
            value={outputText}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleConvert}>
            {isEncoded ? 'Encode' : 'Decode'}
          </Button>
        </Grid>
      </Grid>
    </CenteredContainer>
  );
};

export default Base64Converter;
