import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import MenusImage from '../img/Menus.webp';
import { MenuModel } from '../Models/MenuModel';



type VisibleProps = {
  isVisible: boolean;
};

type FixedProps = {
  isFixed: boolean;
};

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  background-size: cover;
  flex-direction: column;
`;

const Header = styled.header<FixedProps>`
  position: fixed;
  z-index: 20;
  width: 100%;
  top: 0;
  transition: top 0.3s;

  &.hidden {
    top: ${({isFixed}) => (isFixed ? '0' : '-100px')};
  }
`;

const InnerHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin: 0 30px;
`;

const MenuBar = styled.nav`
  display: block;
  align-items: center;
  margin: 40px;
`;

const activeLinkStyle = css`
  font-weight: bold;
  color: #26C2B3;
`;

const MenuLink = styled(Link)<{isActive: boolean}>`
  display: block;
  color: white;
  padding: 10px;
  text-decoration: none;
  text-shadow: 1px 1px 2px black;

  ${({ isActive }) => isActive && activeLinkStyle};

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const ContentArea = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  display: flex;
`;

const Logo = styled.h1`
  position: absolute;
  top: 30px;
  left: 60px;
  z-index: 20;
  font-family: "Gasoek One", sans-serif;
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-style: normal;
  align-content: center;
  text-shadow: 3px 3px 15px black;
  transition: top 0.3s;

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    left: 10px;
    top: 10px;
    
    &.hidden {
      top: -100px;
    }
  }
`;

const MenuList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
`;

const MenuItem = styled.li<VisibleProps>`
  display: list-item;
  position: relative;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  z-index: 10;
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SitemapBtn = styled.li`
  position: absolute;
  display: list-item;
  right: 30px;
  top: 55px;
  width: 38px;
  transform: translateY(-2px);
  margin: 0;
  padding: 7px;

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    right: -10px;
    top: 30px;
  }
`;

const HambergerLine = styled.div`
  width: 30px;
  height: 3px;
  background-color: white;
  box-shadow: 1px 1px 2px black;
  margin: 6px 0;
`;

const MainContent = styled.div<VisibleProps>`
  flex-grow: 1;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  margin-left: ${({isVisible}) => isVisible ? '71px' : '0px'};
`;

const SiteMapContainer = styled.div<VisibleProps>`
  display: ${({isVisible}) => isVisible ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #72edf2 10%, #5151e5 100%);
  transition: visibility 0.5s, opacity 0.5s;
`;

const SiteMapTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  min-width: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;

  h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      
      a {
        color: #333;
        text-decoration: none;
        font-size: 1.2rem;

        &:hover {
          color: #5151e5;
        }
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding-left: 20px;

        li {
          margin-bottom: 5px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SiteMapImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);

  img {
    max-width: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #333;
  margin: 10px 0;
`;

interface MainLayoutProps {
  children: React.ReactNode;
  menus: MenuModel[];
  isFixed: boolean;
  handleMenuSelect: (name: string) => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children = null,
  menus = [],
  isFixed = false,
  handleMenuSelect = () => {}
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [siteMapVisible, setSiteMapVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const mainContentElement = document.getElementsByClassName('main-content')[0];
    mainContentElement.addEventListener('scroll', function() {
      const header = document.getElementById('header') as HTMLElement | null;
      const logo = document.getElementById('logo') as HTMLElement | null;
      const currentScrollPosition = mainContentElement.scrollTop;

      if (currentScrollPosition > 0) {
        header?.classList.add('hidden');
        logo?.classList.add('hidden');
      } else {
        header?.classList.remove('hidden');
        logo?.classList.remove('hidden');
      }
    });
  }, []);

  return (
    <LayoutContainer>
      <Header id="header" isFixed={isFixed}>
        <InnerHeader>
          <Logo id="logo">
            <a href="/" onClick={() => handleMenuSelect('')} style={{ textDecoration: 'none' }}>
              The Guys
            </a>
          </Logo>
          <MenuBar>
            <MenuList>
              <MenuItem isVisible={!siteMapVisible}>
                <MenuLink to="/about" isActive={location.pathname.split('/')[1] === 'about'}>ABOUT US</MenuLink>
              </MenuItem>
              <MenuItem style={{ marginRight: '90px' }} isVisible={!siteMapVisible}>
                <MenuLink to="/userboard" isActive={location.pathname.split('/')[1] === 'userboard'}>USER BOARD</MenuLink>
              </MenuItem>
              <SitemapBtn>
                <a href="#" onClick={() => setSiteMapVisible(v => !v)}>
                  <HambergerLine />
                  <HambergerLine />
                  <HambergerLine />
                </a>
              </SitemapBtn>
            </MenuList>
          </MenuBar>
        </InnerHeader>
      </Header>
      <ContentArea>
        <MainContent className="main-content" isVisible={menus.length > 0}>
          {children}
        </MainContent>
      </ContentArea>
      <SiteMapContainer isVisible={siteMapVisible}>
        <SiteMapTextBox>
          <ul>
            <li>
              <Link to="/about">
                <h3>ABOUT US</h3>
              </Link>
            </li>
            <li>
              <Link to="/userboard">
                <h3>USER BOARD</h3>
              </Link>
            </li>
            <li>
              <h3>WEB UTILS</h3>
              <Line />
              <ul>
                <li><a href="/utils?selectedmenu=qrcode">qrcode</a></li>
                <li><a href="/utils?selectedmenu=base64">base64</a></li>
                <li><a href="/utils?selectedmenu=guid">guid</a></li>
              </ul>
            </li>
          </ul>
        </SiteMapTextBox>
        <SiteMapImgBox>
          <img src={MenusImage} alt="Menus" />
        </SiteMapImgBox>
      </SiteMapContainer>
    </LayoutContainer>
  );
};

export default MainLayout;
