import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import backgroundImage from '../../img/night_tree_river.jpg';
import MenusImage from '../../img/Menus.webp';
import Base64Converter from './Menu/Base64Converter';
import GuidGenerator from './Menu/GuidGenerator';
import QRCodeGenerator from './Menu/QRCodeGenerator';
import Footer from '../Footer';
import BannerSlider from '../BannerSlider';
import Sidebar from '../Sidebar';
import MainLayout from '../MainLayout';

// 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

// 공통 스타일
const linkStyle = css`
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 2rem;
  height: 50px;
`;

const ContentsContainer = styled.main`
  flex-grow: 1;
  overflow-y: auto; /* 내용이 넘치면 스크롤바 생성 */
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  color: #000;
`;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
`;

const MenuLink = styled.a`
  display: block;
  color: white;
  padding: 10px;
  text-decoration: none;
  &:hover {
    background-color: #555;
  }
`;

const MenuButton = styled.button<VisibleProps>`
  background: none;
  border: none;
  color: #000;
  font-size: 2rem;
  cursor: pointer;
  opacity: ${({isVisible}) => (isVisible ? '1' : '0')}; // isVisible prop에 따라 display 속성 설정
`;

type VisibleProps = {
  isVisible: boolean;
};

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 2rem;
  cursor: pointer;
  position: fixed;
  top: 1rem;
  right: 2rem;
  z-index: 1000;
`;


const FullMenu = styled.div<VisibleProps>`
  background-image: linear-gradient(to top, rgba(50, 50, 50, 1), rgba(100, 100, 100, 1));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: left;
  animation: ${fadeIn} 0.5s ease;
  display: ${({isVisible}) => (isVisible ? 'flex' : 'none')}; // isVisible prop에 따라 display 속성 설정
  transition: visibility 0.5s, opacity 0.5s;
  z-index: 999;
`;

const MenuList = styled.ul`
  list-style: none;
  text-align: left;
  margin: 100px;
`;

const MenuItem = styled.li`
  ${linkStyle}
`;

const Introduction = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 2rem;
`;

const IntroText = styled.p`
  font-size: 2.5rem;
  color: #fff;
`;

const StyledUtilsPage = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const UtilsPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(new URL(document.location.href).searchParams.get('selectedmenu') ?? 'utils');

  const handleMenuSelect = (menu: string) => {
    setMenuVisible(false);
    setSelectedMenu(menu);
  };

  const handleMenuWindow = (isVisible: boolean | ((prevState: boolean) => boolean)) => {
    setMenuVisible(isVisible);
  }

  return (
    <MainLayout>
      <StyledUtilsPage>
        <ContentsContainer>
          {selectedMenu === 'utils' && <BannerSlider />}
          {selectedMenu === 'base64' && <Base64Converter />}
          {selectedMenu === 'qrcode' && <QRCodeGenerator />}
          {selectedMenu === 'guid'   && <GuidGenerator />}
        </ContentsContainer>
      </StyledUtilsPage>
    </MainLayout>
  );
};

export default UtilsPage;